import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductsRoutingModule } from './products-routing.module';
import { ProductComponent } from './product/product.component';
import { SharedModule } from '../../shared/shared.module';
import { ProductsComponentComponent } from './products-component/products-component.component';
import { ZtnaComponent } from './ztna/ztna.component';
import { SdWanComponent } from './sd-wan/sd-wan.component';
import { FirewallComponent } from './firewall/firewall.component';
import { SaseComponent } from './sase/sase.component';
import { ProductNDRComponent } from './productndr/productndr.component';
import { GraphQLModule } from '../../graphql.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SigComponent } from './sig/sig.component';






@NgModule({
  declarations: [
    ProductComponent,
    ProductsComponentComponent,
    ZtnaComponent,
    SdWanComponent,
    FirewallComponent,
    SaseComponent,
    ProductNDRComponent,
    SigComponent
  ],
  imports: [
    CommonModule,
    ProductsRoutingModule,
    SharedModule, 
    GraphQLModule,
    // BrowserAnimationsModule
  ]
})
export class ProductsModule { }
