<section class="px-md-5 pt-md-5 pt-3 px-2 d-flex justify-content-center">
    <div class="container">

        <h3 class="clr-blue-dark mb-md-5 mb-2 fw-bold h2 text-center">Quality policy</h3>

        <p class="mt-3">We all at COSGrid Networks are committed to deliver products and
            solutions with the highest quality and aim to delight customers by exceeding
            their expectations with the below 5C Approach as our foundation.</p>

        <p class="mt-5">
            <span class="clr-blue-dark fw-bold">Customer Centric Approach:</span> Ensure all products and services
            delivers
            value to
            customer by meeting the requirements and exceed their expectations
        </p>

        <p>
            <span class="clr-blue-dark fw-bold">Compliance:</span> Adhere to all applicable Industry standards,
            regulatory
            and best
            practices including secure software development lifecycle.
        </p>

        <p>
            <span class="clr-blue-dark fw-bold">Customer Support Excellence:</span> Offer responsive and proactive
            customer
            support,
            ensuring timely resolution of issues
        </p>

        <p>
            <span class="clr-blue-dark fw-bold">Collaboration of Suppliers & Partners:</span> Collaborate with trusted
            suppliers and
            partners who adhere to the same high-quality standards and practices
        </p>

        <p class="mb-5">
            <span class="clr-blue-dark fw-bold">Continuous Improvement:</span> Commit to the innovation, enhancement of
            processes,
            products, and services to deliver better value to customer.
        </p>

        <div class="d-flex justify-content-between align-items-center mt-5">
            <div>
                <p class="fw-bold">Revision Number - 0.3</p>
                <p class="fw-bold">Date - 07/03/2024</p>
            </div>
            <div class="d-flex align-items-center justify-content-center">
                <img src="assets/cosgrid-seal.png" alt="seal" width="60%">
            </div>
            <div>
                <p class="fw-bold">Murugavel Muthu</p>
                <p>Founder & CEO</p>
            </div>
        </div>
    </div>
</section>