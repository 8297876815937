import { NgModule } from '@angular/core';
import { CommonModule, NgFor, NgStyle } from '@angular/common';

import { CompanyRoutingModule } from './company-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactsComponent } from './contacts/contacts.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { AwardsComponent } from './awards/awards.component';
import { CareersComponent } from './careers/careers.component';
import { OpeningsComponent } from './careers/openings/openings.component';
import { PositionComponent } from './careers/openings/position/position.component';
import { EventsComponent } from './events/events.component';
import { QualityPolicyComponent } from './quality-policy/quality-policy.component';
import { NguCarousel, NguCarouselDefDirective, NguCarouselNextDirective, NguCarouselPrevDirective, NguItemComponent, NguTileComponent } from '@ngu/carousel';
import { GraphQLModule } from '../../graphql.module';
import { Apollo } from 'apollo-angular';
import { Meta, Title } from '@angular/platform-browser';
import { FetchMetaService } from '../../queries/Meta/fetchmeta.service';
import { HttpClient, HttpClientModule, HttpHandler } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';


@NgModule({
  declarations: [
    AboutusComponent,
    ContactsComponent,
    AwardsComponent,
    CareersComponent,
    OpeningsComponent,
    PositionComponent,
    EventsComponent,
    QualityPolicyComponent
  ],
  imports: [

    CommonModule,
    CompanyRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    NgStyle,
    FormsModule,
    NguCarousel,
    NguCarouselDefDirective,
    NguCarouselNextDirective,
    NguCarouselPrevDirective,
    NguItemComponent,
    NguTileComponent,
    SharedModule,
    NgFor,
    ToastrModule,
    GraphQLModule,
  ],
  providers: [
    Apollo,
    Meta,
    Title,
    FetchMetaService,
  ]
})
export class CompanyModule { }
