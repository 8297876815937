import { Component } from '@angular/core';

@Component({
  selector: 'app-quality-policy',
  templateUrl: './quality-policy.component.html',
  styleUrl: './quality-policy.component.scss'
})
export class QualityPolicyComponent {

}
