import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Apollo } from 'apollo-angular';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FetchMetaService } from '../../../queries/Meta/fetchmeta.service';
import META_QUERY from '../../../queries/Meta/meta.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { CanonicalService } from '../../../queries/canonical/canonical.service';

@Component({
  selector: 'app-firewall',
  templateUrl: './firewall.component.html',
  styleUrls: ['./firewall.component.scss'],
  animations: [
    trigger('translateTab', [
      state('start', style({
        transform: 'translateX(0)'
      })),
      state('end', style({
        transform: 'translateX(100%)'
      })),
      transition('start <=> end', animate('300ms ease-in-out'))
    ])
  ]
})
export class FirewallComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  firewallIntro: string = 'COSGrid’s NFRxG, is a Next Generation Firewall(NGFW) that a offers robust and adaptive security through a flexible Software Defined Security platform with a Zero Trust Architecture approach in today\'s continously evloving threat landscape. Moving beyond the conventional perimter model of Firewall and traffic rule policies and inspection, COSGrid NGFW is uniquely equipped to combine behavioral based anomaly detection with signature based threat detection, encrypted traffic analytics and deep-packet inspection to identify threats in real-time and protect users, data and organiztion from zero day attacks.'

  firewallOverview: any = {
    title: 'COSGrid NGFW Overview',
    bold: 'COSGrid NGFW',
    description: 'NFRXG utilizes AI-driven security and advanced machine learning to provide comprehensive Threat Protection across all levels. With COSGrid NGFW -NFRXG, Organistaions can gain enhanced insight into their network infrastructure and identify potential threats posed by applications, users, and devices before they materialize',
    imgTitle: 'AI/ML Based NGFW Powered by Zero Trust Security ZTNA 2.0 & Advanced Threat Protection',
    imgUrl: '',
    listTitle: 'COSGrid NFRXG Next Gen Firewall stands out from other firewall as,',
    list: [
      'Complete next-gen firewall capability that is specifically designed to deliver the best protection and performance for the modern encrypted internet.',
      'Integrates with COSGrid Netshield NDR, enabling Automated Threat Response and Synchronized Security to effectively mitigate threats before they can cause any significant damage',
      'Extensive SD-WAN capabilities with ReFleX SD-WAN , allowing you to easily and securely orchestrate and interconnect your various offices and locations.',
      'Support for our SSE and SASE cloud-delivered network security solutions including MicroZAccess ZTNA, SIG/SWG',
      'Secure and easy remote Access with ZTNA 2.0 feature',
      'Detailed Visiblity and Network Traffic with the centralised dashboard.'
    ]
  }

  firewallFeatures: any = [
    {
      sliderTitle: 'Web & Content Filtering',
      imgURL: 'filtering',
      title: 'Web & Content Filtering',
      width: '35%',
      points: [
        'Control access to websites and website categories',
        'Block access to malicious content or websites',
        'Customizable filtering rules to meet educational requirements',
      ],
    },
    {
      sliderTitle: 'Intrusion Detection & Prevention',
      imgURL: 'detection',
      title: 'Intrusion Detection & Prevention',
      width: '35%',
      points: [
        'Detect and block network borne attacks',
        'Protect staff and internal systems from application level attacks',
        'Real-time monitoring for malicious activities and immediate response mechanisms.',
      ]
    },
    {
      sliderTitle: 'Application Visiiblity & Control',
      imgURL: 'planning',
      title: 'Application Visiiblity & Control',
      width: '35%',
      points: [
        'Control access to specific applications Block or control access to Cloud services.',
        'Detailed web access reports (by user, group, website)',
        'Peer-to-peer application control'
      ]
    },
    {
      sliderTitle: 'Secure Remote Access',
      imgURL: 'access-control',
      title: 'Secure Remote Access',
      width: '35%',
      points: [
        'SSL and IPSec VPN support',
        'Two-Factor authentication support (e.g. Secure Token)',
        'High performance VPN concentrator solution',
      ]
    },
    {
      sliderTitle: 'Intelligent Routing',
      imgURL: 'network',
      title: 'Intelligent Routing',
      width: '35%',
      points: [
        'Rich traffic/shaping QOS support',
        'Load-Balancing and ECMP',
        'Dynamic routing (OSPF/BGP/RIP)',

      ]
    },
    {
      sliderTitle: 'Ease of Management',
      imgURL: 'process',
      title: 'Ease of Management',
      width: '35%',
      points: [
        'User-friendly web-based interface for easy configuration and management',
        'Role-based access control',
        'User-friendly GUI that supports multiple administrators'
      ]
    }
  ]

  firewallAdvantages: any = [
    {
      id: 1,
      image: 'detection (1)',
      desc: '<span class="clr-blue-dark">Behaviour based Anomaly Detection</span> using COSGrid NetShield NDR',
      list: [
        'Adds to Signature based protection',
        'Correlation with Signature based protection delivers multi-fold improved threat protection'
      ]
    },
    {
      id: 2,
      image: 'endpoint',
      desc: '<span class="clr-blue-dark">Seamless Protection & Performance</span> enabled by High Performace Appliance',
      list: [
        'Hyperscan: Fast Multi-pattern Regex Matcher',
        '8 to 16 Core Multi-threaded performance without blocking users'
      ]
    },
    {
      id: 3,
      image: 'shield',
      desc: '<span class="clr-blue-dark">Best in Protection</span> through Proof point ETPro subscription or equvivalent for IDS/IPS signatures',
      list: [
        'Proof point ETPro subscription or equvivalent for IDS/IPS signatures',
        'Subscription to URL reputation and categories',
        'Open-source Threat intel to augment'
      ]
    },
    {
      id: 4,
      image: 'monitor',
      desc: '<span class="clr-blue-dark">Single Management Console</span> for managing multiple NGFirewall with Consolidated analytics',
      list: []
    },
    {
      id: 5,
      image: 'encrypted',
      desc: '<span class="clr-blue-dark">Zero Trust and cloud-based web gateway</span> reduce security risk for critical users.',
      list: []
    },
    {
      id: 6,
      image: 'adaptation',
      desc: 'Adaptive Policy & Rules tunning for <span class="clr-blue-dark">seamless operations with Hi-touch support</span>',
      list: []
    },

  ]

  firewallHighlights: any = [
    {
      id: 1,
      imgURL: 'throughput',
      desc: 'NG Firewall Throughput of <span class="clr-blue-dark fw-bold">200 Mbps - 4 Gbps</span>',
      bold: ''
    },
    {
      id: 2,
      imgURL: 'risk-management',
      desc: 'Threat Protection<span class="clr-blue-dark fw-bold"> 200 Mbps - 1 Gbps</span>',
      bold: ''
    },
    {
      id: 3,
      imgURL: 'detection (2)',
      desc: 'IPS <span class="clr-blue-dark fw-bold">300 Mbps - 5 Gbps</span>',
      bold: ''
    },
    {
      id: 4,
      imgURL: 'customize',
      desc: '<span class="clr-blue-dark fw-bold">Customizable</span> rule-set and signatures'
    },
    {
      id: 5,
      imgURL: 'security-system',
      desc: 'Secure <span class="clr-blue-dark fw-bold">Zero Trust</span> Remote Access'
    },
    {
      id: 6,
      imgURL: 'compliant',
      desc: '<span class="clr-blue-dark fw-bold">Compliance and Regulatory</span> - data Protection, GDPR etc..'
    }
  ]

  ngfwBenifits: any = [
    {
      title: '',
      desc: '<span class="clr-blue-dark fw-bold">Comprehensive network filtering appliance</span> that can perform multiple functions- firewall, intrusion prevention system (IPS), web filtering, etc.'
    },
    {
      title: '',
      desc: '<span class="clr-blue-dark fw-bold">Secure Zero Trust Access</span> to all necessary applications on Organisation networks both On-prem & cloud'
    },
    {
      title: '',
      desc: 'Multi-vendor Management with<span class="clr-blue-dark fw-bold"> seamless integration of various security controls</span> in your network'
    },
    {
      title: '',
      desc: '<span class="clr-blue-dark fw-bold">Comprehensive visibility</span> - Possess a thorough understanding of the users, hosts, applications, and content present on enterprise networks'
    },
    {
      title: '',
      desc: 'Imperative to have safeguards in place to <span class="clr-blue-dark fw-bold">counter both known and unknown threats</span>'
    }
  ]
  constructor(
    private fetchMetaService: FetchMetaService,
    private apollo: Apollo,
    private titleService: Title,
    private metaService: Meta,
    private canonicalService: CanonicalService
  ) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalTag()
    if (this.fetchMetaService.getData() == false) {
      let metatags = [];
      this.apollo
        .watchQuery({
          query: META_QUERY,
        })
        .valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          let meta = [];
          meta = (result.data as any).metaTags.data.map((metaTag: any) => ({
            ...metaTag,
          }));
          for (let i = 0; i < meta.length; i++) {
            metatags[meta[i].attributes.URL_ID] = meta[i];
          }
          // console.log("Meta Tags : ", this.metatags);
          this.fetchMetaService.storeData(metatags);
          let metaTagsData = this.fetchMetaService.getData();
          // console.log("Meta Tags ", metaTagsData);
          this.titleService.setTitle(metaTagsData['firewall'].attributes.title);
          this.metaService.updateTag({
            name: 'description',
            content: metaTagsData['firewall'].attributes.description,
          });
          this.metaService.updateTag({
            name: 'keywords',
            content: metaTagsData['firewall'].attributes.keywords,
          });
        });
    } else {
      // console.log("Data Alreday Fetched");
      const data = this.fetchMetaService.getData();
      this.titleService.setTitle(data['firewall'].attributes.title);
      this.metaService.updateTag({
        name: 'description',
        content: data['firewall'].attributes.description,
      });
      this.metaService.updateTag({
        name: 'keywords',
        content: data['firewall'].attributes.keywords,
      });
    }

    // this.klentyService.load();
    // this.input = this.activatedRoute.snapshot.paramMap.get('id');
    // if (!this.id.includes(this.input)) {
    //     this.router.navigate([this.ERROR_LINK + '/' + [this.input]]);
    // }
    // let a = this.id.indexOf(this.input);
  }
  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

}
