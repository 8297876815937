<section class="px-md-5 px-2 pt-5">
    <div class="row">
        <div class="col-12 col-md-6 d-flex flex-column align-items-center align-items-md-start px-1 p-0">
            <img [src]="intro.logo" [alt]="intro.alt" loading="lazy" class="w-80">
            <p class="fs-6 mt-md-5 mt-2 mx-3 mx-md-0">{{intro.description}}</p>

            <div class="w-100 d-flex justify-content-around flex-column flex-md-row align-items-center ">
                <a class="btn btn-primary anchor-button rounded-pill fs-6 fw-semibold"
                    *ngFor="let item of intro.buttons" [href]="item.href" [target]="item.target">{{item.text}}</a>
            </div>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-center align-items-center mt-2 mt-md-0">
            <img [src]="intro.image" [alt]="intro.alt" loading="lazy" class="w-50">
        </div>
    </div>
</section>

<!-- about -->
<section class="px-md-5 px-2 pt-5">
    <h1 class="h2 fw-bold mb-4 text-center">{{about.title}}</h1>
    <p class="fs-6 mt-3 mx-md-5 px-md-5 mx-md-0 text-center">{{about.description}}</p>
    <div class="d-flex justify-content-center align-items-center">
        <img [src]="about.image" [alt]="about.alt" class="w-70">
    </div>
</section>

<!-- features -->
<section class="px-md-5 px-2 pt-5 p-md-4 p-2">
    <h1 class="h2 fw-bold mb-4 text-center">{{features.title}}</h1>
    <p class="fs-6 mt-3 mx-md-5 px-md-5 mx-md-0 text-center">{{features.description}}</p>
    <div class="row">
        <div class="col-md-6 col-12 d-flex align-items-center justify-content-center">
            <img [src]="features.image" [alt]="features.alt" class="w-80">
        </div>
        <div class="col-md-6 col-12">
            <ul class="feature-list">
                @for (item of features.points; track $index) {
                <li [innerHTML]="item" class="my-2">
                </li>
                }
            </ul>
        </div>
    </div>
</section>

<!-- how its work -->
<section class="px-md-5 px-2 pt-5 bg-clr-blue-dark">
    <div class="row flex-column flex-sm-row-reverse my-3">
        <div class="col-md-6 col-12 order-1 order-md-2 pt-3 px-md-5 px-3">
            <h1 class="h2 fw-bold clr-white mb-4">{{howItsWork.title}}</h1>
            <p class="fs-6 mt-3 clr-white">{{howItsWork.description}}</p>
        </div>
        <div class="col-md-6 col-12 order-2 order-md-1 d-flex align-items-center justify-content-center">
            <img [src]="howItsWork.image" [alt]="howItsWork.title" class="w-80">
        </div>
    </div>
</section>

<!-- application filter -->
<section class="px-md-5 px-2 pt-5 p-md-4 p-2">
    <div class="row my-3">
        <div class="col-md-6 col-12 d-flex align-items-center justify-content-center">
            <img [src]="applicationFiltering.image" [alt]="applicationFiltering.title" class="w-80">
        </div>
        <div class="col-md-6 col-12 pt-3 px-md-5 px-3">
            <h1 class="h2 fw-bold mb-4">{{applicationFiltering.title}}</h1>
            <p class="fs-6 mt-3">{{applicationFiltering.description}}</p>
        </div>

    </div>
</section>

<!-- tracking -->
<section class="px-md-5 px-2 pt-5 bg-clr-blue-dark p-md-4 p-2">
    <div class="row flex-column flex-sm-row-reverse my-3">
        <div class="col-md-6 col-12 order-1 order-md-2 pt-3 px-md-5 px-3">
            <h1 class="h2 fw-bold clr-white mb-4">{{tracking.title}}</h1>
            <p class="fs-6 mt-3 clr-white">{{tracking.description}}</p>
        </div>
        <div class="col-md-6 col-12 order-2 order-md-1 d-flex align-items-center justify-content-center">
            <img [src]="tracking.image" [alt]="tracking.title" class="w-80">
        </div>
    </div>
</section>

<!-- advantages -->
<section class="px-md-5 px-2 pt-5">
    <h1 class="h2 fw-bold text-center mb-4 clr-blue-dark">Advantages</h1>
    <div class="row gx-1 align-items-stretch">
        @for (item of advantages; track $index) {
        <div class="col-sm-4 col-12 p-1 h-100">
            <div class="grey-background p-1 d-flex flex-column">
                <div class="d-flex align-items-center mb-3 flex-column justify-content-center h-40 mt-3">
                    <img [src]="item.image" [alt]="item.title" width="80rem">
                    <h4 class="h4 fw-bold mb-4 text-center mt-2">{{item.title}}</h4>
                </div>
                <ul class="flex-grow-1">
                    @for (point of item.points; track $index) {
                    <li>{{point}}</li>
                    }
                </ul>
            </div>
        </div>
        }
    </div>

</section>

<!-- contact us -->
<section class="px-md-5 my-5">
    <div style="background-color: #ededed !important;">
        <div class="container-fluid">
            <div class="d-flex flex-column justify-content-center align-items-center py-4">
                <h1 class="text-center text-md-left fw-bold h2">Interested in this product?</h1>
                <p class="fs-6">Click the button below & submit your details - we will call you back!</p>
                <a class="btn hover-popup" routerLink="/company/contacts"
                    style="background-color: #0078be; color: white">Contact Us</a>
            </div>
        </div>
    </div>
</section>